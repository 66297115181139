import { useState, Fragment } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SiteSelect, SiteHeader, AlertDialog, Tabs } from "../../components";
import { selectSite, selectSiteName } from "../../store/site/selectors";
import {
  getSiteEntities,
  getAvgSiteConditions,
  getSiteConditions,
  deleteSite,
} from "../../store/sites/reducer";

const tabs = ["overview", "areas", "admin"];

const Site = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const name = useSelector(selectSiteName);

  if (!site.entities) {
    console.log("Getting entities");
    dispatch(getSiteEntities());
  }

  const readConditions = site.properties.features.conditions === "Enabled";

  if (!site.avgConditions && readConditions) {
    dispatch(getAvgSiteConditions());
  }

  if (!site.conditions && readConditions) {
    dispatch(getSiteConditions());
  }

  // Remove site
  const [openRemoveSiteDialog, setOpenRemoveSiteDialog] = useState(false);

  const removeSite = () => {
    dispatch(deleteSite(site.id));
    navigate("/sites", { replace: true });
    setOpenRemoveSiteDialog(false);
  };

  return (
    <Fragment>
      <SiteSelect />
      <SiteHeader>
        <Typography variant="h5" component="h5">
          {name}
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpenRemoveSiteDialog(true)}
          startIcon={<DeleteIcon />}
        >
          Remove site
        </Button>
      </SiteHeader>
      <AlertDialog
        open={openRemoveSiteDialog}
        title={`Are you sure you want to remove ${name}?`}
        onClose={() => setOpenRemoveSiteDialog(false)}
        onContinue={removeSite}
        continueText="Remove site"
      />
      <Tabs tabs={tabs} />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          overflow: "scroll",
        }}
      >
        <Outlet />
      </Box>
    </Fragment>
  );
};

export default Site;
