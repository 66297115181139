import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
} from "@mui/material";
import { setSiteId } from "../store/site/reducer";

const SiteCard = ({ site }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, attributes } = site || {};

  const handleClick = () => {
    navigate(`${id}/overview`);
    dispatch(setSiteId(id));
  };

  return (
    <Card onClick={handleClick}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="180"
          image="images/site.jpg"
          alt="site"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            {attributes.displayName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {attributes.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SiteCard;
