import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "./service";

const initialState = {
  sites: [],
  schema: null,
  error: false,
  loading: false,
};

export const getSites = createAsyncThunk(
  "sites/getSites",
  async (_, { rejectWithValue, getState }) => {
    try {
      const token = getState().user.token;
      return await apiService.getSites(token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add site
export const addSite = createAsyncThunk(
  "sites/addSite",
  async function (site, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      return await apiService.postSite(site, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete site
export const deleteSite = createAsyncThunk(
  "sites/deleteSite",
  async function (id, { getState, rejectWithValue }) {
    try {
      const token = getState().user.token;
      return await apiService.deleteSite(id, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Get entities
export const getSiteEntities = createAsyncThunk(
  "sites/getSiteEntities",
  async function (_, { rejectWithValue, getState }) {
    try {
      const id = getState().site.id;
      const token = getState().user.token;
      return await apiService.getEntities(id, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add entity
export const addSiteEntity = createAsyncThunk(
  "sites/addSiteEntity",
  async function ({ entity, path }, { rejectWithValue, getState }) {
    try {
      const id = getState().site.id;
      const token = getState().user.token;
      return await apiService.postEntity(id, token, path, entity);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Edit entity
export const editSiteEntity = createAsyncThunk(
  "sites/editSiteEntity",
  async function ({ entity, path }, { rejectWithValue, getState }) {
    try {
      const id = getState().site.id;
      const token = getState().user.token;
      return await apiService.updateEntity(id, token, path, entity);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete entity
export const deleteSiteEntity = createAsyncThunk(
  "sites/deleteSiteEntity",
  async function (resourcePath, { rejectWithValue, getState }) {
    try {
      const id = getState().site.id;
      const token = getState().user.token;

      return await apiService.deleteEntity(id, resourcePath, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Get site schema
export const getSiteSchema = createAsyncThunk(
  "sites/getSiteSchema",
  async function (_, { rejectWithValue, getState }) {
    const userToken = getState().user.token;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.directoryschema/sites?api-version=1.2`,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );

      if (!res.ok) {
        throw new Error(res.status);
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Get entity schema
export const getChildEntitySchema = createAsyncThunk(
  "sites/getEntitySchema",
  async function ({ siteId, path }, { rejectWithValue, getState }) {
    try {
      const userToken = getState().user.token;
      const res = await fetch(
        `${
          process.env.REACT_APP_ONE_URL
        }/providers/cozify.directoryschema/sites/${siteId}${
          path ? path : ""
        }?api-version=1.2`,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );

      if (!res.ok) {
        throw new Error(res.status);
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getEntitySchema = createAsyncThunk(
  "sites/getEntitySchema1",
  async function (schemaName, { rejectWithValue, getState }) {
    try {
      const userToken = getState().user.token;
      const res = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.directoryschema/sites/schemas/${schemaName}?api-version=1.2`,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );

      if (!res.ok) {
        throw new Error(res.status);
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Conditions
export const getAvgSiteConditions = createAsyncThunk(
  "sites/getAvgSiteConditions",

  async function (_, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const id = getState().site.id;

      return await apiService.getAvgSiteConditions(id, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSiteConditions = createAsyncThunk(
  "sites/getSiteConditions",
  async function (_, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const id = getState().site.id;

      return await apiService.getSiteConditions(id, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Users
export const getUsers = createAsyncThunk(
  "sites/getUsers",
  async function (id, { rejectWithValue, getState }) {
    try {
      const sites = getState().sites.sites;
      const token = getState().user.token;
      const index = sites.findIndex((site) => site.id === id);
      const roleDefinitions = sites[index].roleDefinitions;
      const roleAssignments = sites[index].roleAssignments;
      const userIds = [
        ...new Set(roleAssignments.map((item) => item["principalId"])),
      ];

      const res = await Promise.allSettled(
        userIds.map((userId) =>
          fetch(
            `${process.env.REACT_APP_ONE_URL}/site/${id}/providers/cozify.users/users/${userId}`,
            {
              headers: { Authorization: "Bearer " + token },
            }
          ).then((res) => res.json())
        )
      );

      let users = res.map((object) => object["value"]);

      users = users.map((user) => {
        const roles = roleAssignments
          .filter((roleAssignment) => roleAssignment.principalId === user.id)
          .map((item) => {
            const role = roleDefinitions.find(
              (roleDefinition) => roleDefinition.id === item.roleDefinitionId
            );
            return role;
          });

        return {
          ...user,
          roles: roles,
        };
      });

      // if (!res.ok) {
      //   throw new Error(res.status);
      // }

      return { id, users };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    clearSchema(state, action) {
      state.schema = null;
    },
  },
  extraReducers: {
    [getSites.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getSites.fulfilled]: (state, action) => {
      state.loading = false;
      state.sites = action.payload;
    },
    [getSites.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      console.log(action);
    },
    // Add site
    [addSite.pending]: (state) => {},
    [addSite.fulfilled]: (state, action) => {
      state.sites.push(action.payload);
    },
    [addSite.rejected]: (state, action) => {
      console.log(action);
    },
    // Update site
    // Delete site
    [deleteSite.pending]: (state) => {},
    [deleteSite.fulfilled]: (state, action) => {
      state.sites = state.sites.filter((site) => site.id !== action.payload.id);
    },
    [deleteSite.rejected]: (state, action) => {
      console.log(action);
    },
    // Get site entities
    [getSiteEntities.pending]: (state) => {},
    [getSiteEntities.fulfilled]: (state, action) => {
      const site = state.sites.find((site) => site.id === action.payload.id);
      const index = state.sites.indexOf(site);

      state.sites[index].entities = action.payload.data;
    },
    [getSiteEntities.rejected]: (state, action) => {
      console.log(action);
    },
    // Add site entity
    [addSiteEntity.pending]: (state) => {},
    [addSiteEntity.fulfilled]: (state, action) => {
      const site = state.sites.find((site) => site.id === action.payload.id);
      const index = state.sites.indexOf(site);
      state.sites[index].entities.push(action.payload.data);
    },
    [addSiteEntity.rejected]: (state, action) => {
      console.log(action);
    },
    // Edit site entity
    [editSiteEntity.pending]: (state) => {},
    [editSiteEntity.fulfilled]: (state, action) => {
      const site = state.sites.find((site) => site.id === action.payload.id);
      const index = state.sites.indexOf(site);
      state.sites[index].entities = state.sites[index].entities.map((entity) =>
        entity.path === action.payload.path ? action.payload.data : entity
      );
    },
    [editSiteEntity.rejected]: (state, action) => {
      console.log(action);
    },
    // Delete site entity
    [deleteSiteEntity.pending]: (state) => {},
    [deleteSiteEntity.fulfilled]: (state, action) => {
      const site = state.sites.find((site) => site.id === action.payload.id);
      const index = state.sites.indexOf(site);
      state.sites[index].entities = state.sites[index].entities.filter(
        (entity) => entity.path !== action.payload.resourcePath
      );
    },
    [deleteSiteEntity.rejected]: (state, action) => {
      console.log(action);
    },
    // Get site schema
    [getSiteSchema.pending]: (state) => {},
    [getSiteSchema.fulfilled]: (state, action) => {
      state.schema = action.payload;
    },
    [getSiteSchema.rejected]: (state, action) => {
      console.log(action);
    },
    // Get child entity schema
    [getChildEntitySchema.pending]: (state) => {},
    [getChildEntitySchema.fulfilled]: (state, action) => {
      state.schema = action.payload;
    },
    [getChildEntitySchema.rejected]: (state, action) => {
      console.log(action);
    },
    // Get entity schema
    [getEntitySchema.pending]: (state) => {},
    [getEntitySchema.fulfilled]: (state, action) => {
      state.schema = action.payload;
    },
    [getEntitySchema.rejected]: (state, action) => {
      console.log(action);
    },
    // Get users
    [getUsers.pending]: (state) => {},
    [getUsers.fulfilled]: (state, action) => {
      const index = state.sites.findIndex(
        (site) => site.id === action.payload.id
      );
      state.sites[index].users = action.payload.users;
    },
    [getUsers.rejected]: (state, action) => {
      console.log(action);
    },
    // Get average site conditions
    [getAvgSiteConditions.pending]: (state) => {},
    [getAvgSiteConditions.fulfilled]: (state, action) => {
      const index = state.sites.findIndex(
        (site) => site.id === action.payload.id
      );
      state.sites[index].avgConditions = action.payload.data;
    },
    [getAvgSiteConditions.rejected]: (state, action) => {
      console.log(action);
    },
    // Get site conditions
    [getSiteConditions.pending]: (state) => {},
    [getSiteConditions.fulfilled]: (state, action) => {
      const index = state.sites.findIndex(
        (site) => site.id === action.payload.id
      );
      state.sites[index].conditions = action.payload.data;
    },
    [getSiteConditions.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const { clearSchema } = sitesSlice.actions;

export default sitesSlice.reducer;
