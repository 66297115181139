import { Paper, Typography } from "@mui/material";

const Widget = (props) => {
  const { value = "", name = "" } = props;

  return (
    <Paper
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 100,
      }}
      elevation={0}
      variant="outlined"
    >
      <Typography variant="h6" component="h6">
        {value}
      </Typography>
      <Typography variant="overline">{name}</Typography>
    </Paper>
  );
};

export default Widget;
