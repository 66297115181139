import { Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import App from "./App";
import AppBar from "./AppBar";
import AppContainer from "./AppContainer";

const AppLayout = () => {
  const { token } = useAuth();

  if (!token) return <Outlet />;
  // Don't render layout before there's a token

  return (
    <App>
      <AppBar />
      <AppContainer>
        <Outlet />
      </AppContainer>
    </App>
  );
};

export default AppLayout;
