import { Modal as MUIModal, Backdrop, Fade, Stack } from "@mui/material";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  minWidth: 300,
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
};

const modalStyles2 = {
  position: "absolute",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 0,
  overflow: "scroll",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function Modal({
  open,
  onClose,
  label = "modal",
  fullWidth = false,
  children,
}) {
  return (
    <MUIModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
      aria-labelledby={label}
    >
      <Fade in={open}>
        <Stack spacing={4} sx={fullWidth ? modalStyles2 : modalStyles}>
          {children}
        </Stack>
      </Fade>
    </MUIModal>
  );
}
