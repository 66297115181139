import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Typography, TextField, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Modal, Spinner, QrTable } from "../../components";
import { getCompanies, createCompany } from "../../store/qr/reducer";

const Qr = () => {
  const dispatch = useDispatch();
  const { loading, error, companies } = useSelector((state) => state.qr);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  const [company, setCompany] = useState({
    companyId: "",
    name: "",
    redirectUrl: "https://www.cozify.fi",
  });

  const clearCompany = () => {
    setCompany({
      companyId: "",
      name: "",
      redirectUrl: "https://www.cozify.fi",
    });
  };

  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);

  const addCompany = () => {
    if (!validationError) {
      dispatch(createCompany(company));
      setAddCompanyModalOpen(false);
      clearCompany();
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>An error occurred.</p>;
  }

  return (
    <Fragment>
      <Box sx={{ my: 2 }}>
        <Button
          onClick={() => setAddCompanyModalOpen(true)}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Add company
        </Button>
      </Box>
      {companies &&
        companies.map((c) => {
          return <QrTable key={c.companyId} company={c} />;
        })}
      <Modal
        open={addCompanyModalOpen}
        onClose={() => {
          setAddCompanyModalOpen(false);
          clearCompany();
        }}
      >
        <Typography variant="h5" component="h5">
          Add company
        </Typography>
        <TextField
          label="Company id"
          value={company.companyId}
          onChange={(e) => {
            setCompany({ ...company, companyId: e.target.value });
            setValidationError(!/^[a-z0-9]*$/.test(e.target.value));
          }}
          error={validationError}
          helperText={validationError && "*Lowercase letters and numbers"}
        />
        <TextField
          label="Company name"
          value={company.name}
          onChange={(e) => setCompany({ ...company, name: e.target.value })}
        />
        <TextField
          label="Redirect url"
          value={company.redirectUrl}
          onChange={(e) =>
            setCompany({ ...company, redirectUrl: e.target.value })
          }
        />
        <Stack direction="row" spacing={2} alignSelf="flex-end">
          <Button
            onClick={() => {
              setAddCompanyModalOpen(false);
              clearCompany();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={addCompany}>
            Add company
          </Button>
        </Stack>
      </Modal>
    </Fragment>
  );
};

export default Qr;
