import { Fragment } from "react";

import { parseItemPath } from "../utils";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

function areasByStair(areas) {
  const areasByStair = {};

  areas.forEach((area) => {
    const { stair } = parseItemPath(area);
    if (areasByStair.hasOwnProperty(stair)) {
      areasByStair[stair].push(area);
    } else {
      areasByStair[stair] = [area];
    }
  });

  return Object.entries(areasByStair);
}

const AreasList = ({ areas }) => {
  const sortedAreas = areasByStair(areas);

  const statusColor = (status) => {
    switch (status) {
      case "Ok":
        return "success.main";
      case "Error":
        return "error.main";
      case "Warn":
        return "warning.main";
      default:
        return "grey.100";
    }
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
        "& ul": { padding: 0 },
        border: "1px solid",
      }}
      subheader={<li />}
    >
      {sortedAreas.map((group) => (
        <li key={`section-${group[0]}`}>
          <ul>
            <ListSubheader
              sx={{ border: "1px solid" }}
            >{`Stair ${group[0].toUpperCase()}`}</ListSubheader>
            {group[1].map((area) => (
              <ListItem key={area.id} sx={{ borderLeft: "10px solid green" }}>
                <ListItemText primary={area.attributes.displayName} />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

export default AreasList;
