import { combineReducers } from "redux";
import sitesReducer from "./sites/reducer";
import siteReducer from "./site/reducer";
import areaReducer from "./area/reducer";
import userReducer from "./user/userSlice";
import qrReducer from "./qr/reducer";

export const reducers = combineReducers({
  sites: sitesReducer,
  site: siteReducer,
  area: areaReducer,
  user: userReducer,
  qr: qrReducer,
});
