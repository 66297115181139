import { useSelector } from "react-redux";
import { Box, Typography, Stack } from "@mui/material";
import { selectSiteContacts } from "../store/site/selectors";

const SiteContacts = () => {
  const { managementContacts, maintenanceContacts, boardContacts } =
    useSelector(selectSiteContacts);

  return (
    <Box>
      <Typography variant="h6" component="h6">
        Contacts
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} sx={{ my: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="overline">Management</Typography>
          {managementContacts.length ? (
            <p>{managementContacts.length} contact(s)</p>
          ) : (
            <p>No contacts</p>
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="overline">Maintenance</Typography>
          {maintenanceContacts.length ? (
            <p>{managementContacts.length} contact(s)</p>
          ) : (
            <p>No contacts</p>
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="overline">Board</Typography>
          {boardContacts.length ? (
            <p>{managementContacts.length} contact(s)</p>
          ) : (
            <p>No contacts</p>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default SiteContacts;
