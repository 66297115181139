import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AreaDevice = ({ device }) => {
  const { attributes } = device || {};
  const { deviceType, displayName, capabilities, sourceConfiguration } =
    attributes;

  return (
    <Accordion variant="outlined" sx={{ my: 0.5 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{displayName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {capabilities && (
          <Stack
            direction="row"
            spacing={0.25}
            sx={{ mb: 2, flexWrap: "wrap" }}
          >
            {capabilities.map((capability) => (
              <Chip
                key={capability}
                label={capability}
                variant="outlined"
                sx={{ mb: 0.25 }}
              />
            ))}
          </Stack>
        )}
        {deviceType && (
          <Typography variant="subtitle1" sx={{ mr: 2 }}>
            Device type: {deviceType}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AreaDevice;
