import { Stack } from "@mui/material";
import {
  SiteAddressDetails,
  SiteConsumptionDetails,
  SiteContacts,
} from "../../components";

const SiteAdmin = () => {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <SiteAddressDetails />
      <SiteConsumptionDetails />
      <SiteContacts />
    </Stack>
  );
};

export default SiteAdmin;
