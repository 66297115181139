import { createSelector } from "@reduxjs/toolkit";

export const selectSites = (state) => state.sites.sites;

export const selectSiteById = createSelector(
  [selectSites, (state, id) => id],
  (sites, id) => sites.find((site) => site.id === id)
);

export const selectSchema = (state) => state.sites.schema;
