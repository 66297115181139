/**
 * Low-level functionality for Cozify ONE API responses.
 */

import { chunk, extend, fromPairs, last } from "lodash-es";
import { DIRECTORY_TYPES, isTreeLeaf } from "./types";

export const entityTypeFromPath = (path) => {
  const re = /\/([^/]+)\/[^/]+$/;

  const match = path.match(re);

  if (!match) {
    return null;
  }

  return match[1];
};

export const isHotWaterDevice = (device) => {
  return device.attributes?.hotWater === true;
};

/**
 * @example
 *     parseItemPath({path: "/building/1/floor/3"})
 *     // {building: "1", floor: "3"}
 */
export const parseItemPath = (item) => {
  const pathArr = item.path.split("/");

  pathArr.shift();
  const path = chunk(pathArr, 2);
  return fromPairs(path);
};

/**
 * Get reference to another entity from Cozify ONE path property.
 *
 * @param {string} path - path from cozify one API entity. E.g.
 * "/building/1/stair/a/floor/1/area/1/device/temp-1"
 * @param {string} entity - type of the entity, e.g. "area" or "building"
 *
 * @example
 * entityReferenceFromPath('/building/1/stair/a/floor/1/area/1/device/temp-1', 'floor')
 * // '/building/1/stair/a/floor/1'
 */
export const entityReferenceFromPath = (path, entity) => {
  const re = new RegExp(`^.*/${entity}/[^/]+`);
  const regexMatch = path.match(re);

  if (!regexMatch) {
    return null;
  }

  return regexMatch[0];
};

/**
 * Create human friendly version of site address.
 *
 * @example
 * formatSiteAddress(site)
 * // Aleksis Kiven katu 11, Tampere
 */
export const formatSiteAddress = (site) => {
  if (
    site.attributes?.postalAddress?.address1 &&
    site.attributes?.postalAddress?.town
  ) {
    return `${site.attributes?.postalAddress?.address1}, ${site.attributes?.postalAddress?.town}`;
  } else if (site.attributes?.postalAddress?.address1) {
    return site.attributes?.postalAddress?.address1;
  } else {
    return "No address";
  }
};

/**
 * Get most suitable human readable name for a Cozify ONE entity.
 *
 * See https://one.dev.cozify.fi/openapi/index.html#/Directory/GetSiteEntities
 *
 * Also https://github.com/cozify/portal/issues/24
 *
 * @param {object} entity - Cozify one "entity"
 */
export const formatEntityName = (entity) => {
  if (entity.displayName) {
    return entity.displayName;
  }

  if (entity.name) {
    return entity.name;
  }

  if (entity.path) {
    return last(entity.path.split("/"));
  }

  throw new Error(
    "Too little details to infer display name: ",
    JSON.stringify(entity)
  );
};

/**
 * Get reference to parent entity from the Cozify ONE path property.
 *
 * @example
 * parentEntityReference('/site/1/building/2')
 * // /site/1
 */
export const parentEntityReference = (path) => {
  const re = /^(.+)\/.*\//;

  const res = path.match(re);

  if (!res) {
    return null;
  }

  return res[1];
};

/**
 * @example
 * pathWithoutSite(/site/1/building/234)
 * /building/234
 */
export const pathWithoutSite = (path) => {
  const re = /^\/site\/[^/]+(\/.*)/;

  const match = path.match(re);

  if (!match) {
    return null;
  }

  return match[1];
};

export class BaseItem {
  constructor(item, siteId, pId) {
    this.id = item.id;
    this.pId = pId;
    this.attributes = item.attributes;
    this.properties = item.properties || {};
    this.key = item.id;
    this.name = item.name;
    this.displayName = item.displayName || item.name;
    this.description = item.description;
    this.type = item.type || "N/A";
    this.siteId = siteId;
    this.path = `/site/${siteId}${item.path ? item.path : ""}`;
    this.isLeaf = isTreeLeaf(this.type);
    this.nextLevel = item.depth ? item.depth + 2 : 2;
    this.selectable = this.type === DIRECTORY_TYPES.Device;

    if (item.path) {
      extend(this, parseItemPath(item));
    }
  }

  toObject() {
    return { ...this, selectable: true };
  }
}
