import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const AlertDialog = ({
  open,
  onClose,
  label = "alert-dialog",
  title,
  body = "This action cannot be undone.",
  onContinue,
  continueText = "Continue",
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={label}>
      <Box sx={{ p: 2 }}>
        <DialogTitle>{title}</DialogTitle>
        {body && (
          <DialogContent>
            <DialogContentText>{body}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onContinue} variant="contained" color="error">
            {continueText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AlertDialog;
