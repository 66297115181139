import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  Box,
  Stack,
  Paper,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "./Modal";
import JsonSchemaForm from "./JsonSchemaForm";
import Spinner from "./Spinner";
import { selectSiteId } from "../store/site/selectors";
import { selectSchema } from "../store/sites/selectors";
import {
  addSiteEntity,
  editSiteEntity,
  deleteSiteEntity,
  clearSchema,
  getChildEntitySchema,
  getEntitySchema,
} from "../store/sites/reducer";

const Node = ({ node = {}, children = [] }) => {
  const dispatch = useDispatch();
  const siteId = useSelector(selectSiteId);
  const schema = useSelector(selectSchema);
  const { path, depth } = node;

  // Add entity
  const [addEntityModalOpen, setAddEntityModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState("");

  const getAddEntitySchema = (e) => {
    e.stopPropagation();
    setAddEntityModalOpen(true);
    dispatch(getChildEntitySchema({ siteId, path }));
  };

  const addEntity = ({ formData }) => {
    const entity = { ...formData, Type: selectedEntity };
    dispatch(addSiteEntity({ entity, path }));
    handleModalClose();
  };

  // Edit entity
  const [editEntityModalOpen, setEditEntityModalOpen] = useState(false);

  const getEditEntitySchema = (e) => {
    e.stopPropagation();
    setEditEntityModalOpen(true);
    dispatch(getEntitySchema(node.type));
  };

  const editEntity = ({ formData }) => {
    const entity = { ...formData };
    setEditEntityModalOpen(false);
    dispatch(editSiteEntity({ path, entity }));
    handleModalClose();
  };

  // Clear schema
  const handleModalClose = () => {
    dispatch(clearSchema());
    setSelectedEntity("");
    setAddEntityModalOpen(false);
    setEditEntityModalOpen(false);
  };

  //Remove entity
  const removeEntity = (e) => {
    e.stopPropagation();
    dispatch(deleteSiteEntity(path));
  };

  // Framer motion stuff
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const arrowVariants = {
    right: { rotate: "0deg" },
    down: { rotate: "90deg" },
  };

  return (
    <Box
      sx={{
        ml: `${depth / 2}rem `,
      }}
    >
      <Paper variant="outlined">
        <Stack
          onClick={toggleVisibility}
          sx={{
            cursor: "pointer",
          }}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {children.length > 0 && (
              <motion.button
                variants={arrowVariants}
                animate={isVisible ? "down" : "right"}
                initial={false}
                style={{ border: "none", background: "none" }}
              >
                <ChevronRightIcon fontSize="small" />
              </motion.button>
            )}

            <Typography variant="p" component="p" sx={{ mx: 1 }}>
              {node.displayName && node.displayName}
              {node.attributes?.displayName && node.attributes.displayName}
            </Typography>
            <IconButton
              onClick={getAddEntitySchema}
              aria-label="Add entity"
              color="primary"
            >
              <AddBoxIcon />
            </IconButton>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {path && (
              <IconButton
                onClick={getEditEntitySchema}
                aria-label="Edit entity"
                color="primary"
              >
                <EditIcon />
              </IconButton>
            )}
            {path && (
              <IconButton
                onClick={removeEntity}
                aria-label="Delete entity"
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Paper>

      {/* Add entity */}
      <Modal open={addEntityModalOpen} onClose={handleModalClose}>
        <Typography variant="h5" component="h5">
          Add entities to {path ? path : "site"}
        </Typography>
        {schema ? (
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel id="select-entity">Select entity</InputLabel>
            <Select
              labelId="select-entity"
              value={selectedEntity}
              label="Select entity"
              onChange={(e) => setSelectedEntity(e.target.value)}
            >
              {Object.keys(schema).map((entity) => (
                <MenuItem value={entity}>{entity}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Spinner />
        )}
        {schema && selectedEntity && (
          <JsonSchemaForm
            schema={schema[selectedEntity]}
            onSubmit={addEntity}
          />
        )}
      </Modal>

      {/* Edit entity */}
      <Modal open={editEntityModalOpen} onClose={handleModalClose}>
        <Typography variant="h5" component="h5">
          Edit entity
        </Typography>
        <p>{JSON.stringify(node, null, 2)}</p>
        {schema && (
          <JsonSchemaForm
            schema={schema}
            // formData={node} --> this doesn't work
            onSubmit={editEntity}
          />
        )}
      </Modal>

      {/* Children */}
      <AnimatePresence initial={false}>
        {isVisible && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              overflow: "hidden",
            }}
          >
            {children && children}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Node;
