import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Spinner, Modal, SiteCard, JsonSchemaForm } from "../../components";
import { getSites, getSiteSchema, addSite } from "../../store/sites/reducer";

const Sites = () => {
  const dispatch = useDispatch();
  const { loading, error, sites, schema } = useSelector((state) => state.sites);
  const [addSiteModalOpen, setAddSiteModalOpen] = useState(false);

  const getSchema = () => {
    dispatch(getSiteSchema());
    setAddSiteModalOpen(true);
  };

  const handleSubmit = ({ formData }) => {
    dispatch(addSite(formData));
    setAddSiteModalOpen(false);
  };

  if (!sites.length) {
    dispatch(getSites());
  }

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>An error occured.</p>;
  }

  return (
    <Fragment>
      <Box sx={{ my: 2 }}>
        <Button variant="contained" onClick={getSchema} startIcon={<AddIcon />}>
          Add site
        </Button>
      </Box>

      <Modal
        open={addSiteModalOpen}
        onClose={() => setAddSiteModalOpen(false)}
        fullWidth
      >
        <Typography variant="h5" component="h5" sx={{ mx: "auto" }}>
          Add site
        </Typography>
        {schema ? (
          <JsonSchemaForm schema={schema} onSubmit={handleSubmit} />
        ) : (
          <p>Loading site schema...</p>
        )}
      </Modal>
      <Grid container spacing={2} sx={{ my: 1 }}>
        {sites &&
          sites.map((site) => (
            <Grid key={site.id} item xs={12} sm={6} md={4} lg={3}>
              <SiteCard site={site} />
            </Grid>
          ))}
      </Grid>
    </Fragment>
  );
};

export default Sites;
