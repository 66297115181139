import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Paper, Box, Typography } from "@mui/material";
import { selectAreaConditionsByPath } from "../store/area/selectors";

const AreaCard = ({ area, onClick }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { displayName } = area.attributes || {};
  const { name } = area || {};

  const areaConditions = useSelector((state) =>
    selectAreaConditionsByPath(state, area.path)
  );

  const { status = "Error" } = areaConditions || {};

  const statusColor = (status) => {
    switch (status) {
      case "Ok":
        return "success.main";
      case "Error":
        return "error.main";
      case "Warn":
        return "warning.main";
      default:
        return "grey.100";
    }
  };

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        width: matches ? 180 : 50,
        cursor: "pointer",
      }}
      onClick={onClick}
      elevation={0}
      variant="outlined"
    >
      <Box
        sx={{
          width: "5px",
          height: "36.5px",
          bgcolor: statusColor(status),
          borderRadius: "4px 0 0 4px",
          mr: 1,
        }}
      ></Box>
      <Typography variant="overline">{displayName || name}</Typography>
    </Paper>
  );
};

export default AreaCard;
