import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { SiteUsers } from "../../components";
import { selectSites } from "../../store/sites/selectors";
import { getSites } from "../../store/sites/reducer";

const Users = () => {
  const dispatch = useDispatch();
  const sites = useSelector(selectSites);

  if (!sites.length) {
    dispatch(getSites());
  }

  return (
    <Box sx={{ my: 4 }}>
      {sites.length &&
        sites.map((site) => {
          return <SiteUsers key={site.id} id={site.id} />;
        })}
    </Box>
  );
};

export default Users;
