import { Box } from "@mui/material";
import Form from "@rjsf/material-ui/v5";

const JsonSchemaForm = ({ schema = {}, onSubmit = Function.prototype }) => {
  return (
    <Box sx={{ maxWidth: 900 }}>
      <Form schema={schema} onSubmit={onSubmit} />
    </Box>
  );
};

export default JsonSchemaForm;
