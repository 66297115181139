import { Fragment } from "react";
import { useSelector } from "react-redux";
import { AreaDevice } from "../../components";
import { selectAreaDevices } from "../../store/area/selectors";

const AreaDevices = () => {
  const devices = useSelector(selectAreaDevices);

  if (devices.length) {
    return (
      <Fragment>
        {devices.map((device) => (
          <AreaDevice key={device.path} device={device} />
        ))}
      </Fragment>
    );
  }

  return null;
};

export default AreaDevices;
