import { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Divider, Box } from "@mui/material";
import { AreaCard } from "../../components";
import { selectSiteAreas } from "../../store/site/selectors";
import { setAreaPath } from "../../store/area/reducer";

const SiteAreas = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const areas = useSelector(selectSiteAreas);
  const [currentArea, setCurrentArea] = useState(areas[0]);

  useEffect(() => {
    if (currentArea) {
      navigate(`${currentArea.id}/tab1`);
      dispatch(setAreaPath(currentArea.path));
    }
    // eslint-disable-next-line
  }, [dispatch, currentArea]);

  if (!currentArea) {
    return <p>0 areas</p>;
  }

  return (
    <Stack
      direction="row"
      spacing={{ xs: 1, sm: 2, md: 4 }}
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        height: "100%",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <Stack
        spacing={1}
        sx={{
          height: "100%",
          minWidth: 50,
          overflow: "scroll",
        }}
      >
        {areas.map((area) => (
          <AreaCard
            key={area.path}
            area={area}
            onClick={() => setCurrentArea(area)}
          />
        ))}
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
};

export default SiteAreas;
