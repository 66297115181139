import { Stack } from "@mui/material";

const AreaHeader = ({ children }) => {
  return (
    <Stack
      sx={
        {
          // border: "1px solid"
        }
      }
      component="header"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
    </Stack>
  );
};

export default AreaHeader;
