import { createSelector } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { selectSites } from "../sites/selectors";
import { parseItemPath } from "../../utils";
import { formatSiteAddress } from "../../services/mappers/baseDirectoryItem";

export const selectSiteId = (state) => state.site.id;

export const selectSite = createSelector(
  [selectSites, selectSiteId],
  (sites, id) => sites.find((site) => site.id === id)
);

export const selectSiteName = createSelector([selectSite], (site) => {
  const { displayName } = site.attributes || {};
  return displayName;
});

export const selectSiteEntities = createSelector([selectSite], (site) => {
  if (!site.entities) {
    return [];
  }
  return site.entities;
});

export const selectSiteBuildings = createSelector([selectSite], (site) => {
  if (!site.entities) {
    return [];
  }

  const buildings =
    site.entities.filter((entity) => entity.type === "building") || [];
  return buildings;
});

export const selectSiteStairs = createSelector([selectSite], (site) => {
  if (!site.entities) {
    return [];
  }

  const stairs =
    site.entities.filter((entity) => entity.type === "stair") || [];
  return stairs;
});

export const selectSiteFloors = createSelector([selectSite], (site) => {
  if (!site.entities) {
    return [];
  }

  const floors =
    site.entities.filter((entity) => entity.type === "floor") || [];
  return floors;
});

export const selectSiteAreas = createSelector([selectSite], (site) => {
  if (!site.entities) {
    return [];
  }

  const areas =
    site.entities
      .filter((entity) => entity.type === "area")
      .map((area) => {
        return {
          ...area,
          id: uuidv4(),
        };
      }) || [];

  return areas;
});

export const selectSiteDevices = createSelector([selectSite], (site) => {
  if (!site.entities) {
    return [];
  }

  const devices =
    site.entities.filter((entity) => entity.type === "device") || [];
  return devices;
});

export const selectSiteContacts = createSelector([selectSite], (site) => {
  if (!site.entities) {
    return [];
  }

  const contacts = site.entities.filter((entity) => entity.type === "contact");

  const parsedContacts = contacts.map((contact) => ({
    ...contact,
    type: parseItemPath(contact).contacts,
  }));

  const managementContacts = parsedContacts.filter(
    (contact) => contact.type === "management"
  );

  const maintenanceContacts = parsedContacts.filter(
    (contact) => contact.type === "maintenance"
  );

  const boardContacts = parsedContacts.filter(
    (contact) => contact.type === "board"
  );

  return {
    managementContacts,
    maintenanceContacts,
    boardContacts,
  };
});

export const selectSiteConditions = createSelector([selectSite], (site) => {
  if (!site.conditions) {
    return [];
  }

  return site.conditions;
});

export const selectAvgSiteConditions = createSelector([selectSite], (site) => {
  if (!site.avgConditions) {
    return [];
  }

  const temperature = site.avgConditions.find(
    (c) => c.capability === "TEMPERATURE"
  );

  const humidity = site.avgConditions.find((c) => c.capability === "HUMIDITY");

  return {
    avgTemperature: temperature ? temperature.values.avg : null,
    avgHumidity: humidity ? humidity.values.avg : null,
  };
});

export const selectSiteAddressDetails = createSelector([selectSite], (site) => {
  const postalAddress = formatSiteAddress(site);

  return postalAddress;
});

export const selectSiteConsumptionDetails = createSelector(
  [selectSite],
  (site) => {
    const { attributes } = site || {};

    const consumptionDetails = {
      electricityTransmissionCents: attributes?.electricityTransmissionCents,
      electricityTaxCents: attributes?.electricityTaxCents,
      coldWaterEurosPerM3: attributes?.coldWaterEurosPerM3,
      hotWaterEurosPerM3: attributes?.hotWaterEurosPerM3,
    };
    return consumptionDetails;
  }
);
