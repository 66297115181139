import { createSelector } from "@reduxjs/toolkit";
import {
  selectSite,
  selectSiteAreas,
  selectSiteDevices,
  selectSiteConditions,
} from "../site/selectors";

import { selectSites } from "../sites/selectors";
import { entityReferenceFromPath } from "../../utils";

export const selectAreaPath = (state) => state.area.path;

export const selectArea = createSelector(
  [selectSiteAreas, selectAreaPath],
  (areas, path) => {
    const area = areas.find((area) => area.path === path) || {};
    return area;
  }
);

export const selectAreaName = createSelector([selectArea], (area) => {
  const { displayName } = area.attributes || {};
  const { name } = area || {};
  return displayName || name;
});

export const selectAreaDevices = createSelector(
  selectSiteDevices,
  selectAreaPath,
  (devices, areaPath) => {
    const areaDevices =
      devices.filter((device) => device.path.includes(areaPath)) || [];
    return areaDevices;
  }
);

export const selectAreaConditions = createSelector(
  selectSite,
  selectAreaPath,
  (site, areaPath) => {
    if (!site.conditions) {
      return {};
    }

    const areaConditions = site.conditions.find(
      (condition) =>
        entityReferenceFromPath(condition.path, "area") === areaPath
    );

    if (!areaConditions) {
      return {};
    }

    const { status, values } = areaConditions;

    const temperature = values.find(
      (c) => c.capability === "TEMPERATURE"
    )?.value;

    const humidity = values.find((c) => c.capability === "HUMIDITY")?.value;

    return { status, temperature, humidity };
  }
);

export const selectSiteById = createSelector(
  [selectSites, (state, id) => id],
  (sites, id) => sites.find((site) => site.id === id)
);

export const selectAreaConditionsByPath = createSelector(
  selectSiteConditions,
  (state, path) => path,
  (conditions, path) => {
    const areaConditions = conditions.find(
      (condition) => entityReferenceFromPath(condition.path, "area") === path
    );

    if (!areaConditions) {
      return {};
    }

    const { status, values } = areaConditions;

    const temperature = values.find(
      (c) => c.capability === "TEMPERATURE"
    )?.value;

    const humidity = values.find((c) => c.capability === "HUMIDITY")?.value;

    return { status, temperature, humidity };
  }
);

export const selectAreaResidents = createSelector(selectArea, (area) => {
  const { numberOfResidents } = area.attributes || {};
  return numberOfResidents;
});
