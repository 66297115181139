import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  Box,
  Typography,
  Table as MUITable,
  TableBody,
  TableCell,
  Link,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Pagination, Modal, AlertDialog } from ".";
import {
  updateCompany,
  deleteCompany,
  createProduct,
  updateProduct,
  deleteProduct,
} from "../store/qr/reducer";

const QrTable = (props) => {
  const dispatch = useDispatch();
  const {
    name = "",
    companyId = "",
    products = [],
    redirectUrl = "https://www.cozify.fi",
  } = props.company || {};

  // Company actions
  const [company, setCompany] = useState({
    companyId,
    name,
    redirectUrl,
  });

  const clearCompany = () => {
    setCompany({
      companyId: "",
      name: "",
      redirectUrl: "https://www.cozify.fi",
    });
  };

  // Edit company
  const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const editCompany = () => {
    dispatch(updateCompany({ companyId, company }));
    setEditCompanyModalOpen(false);
    clearCompany();
  };

  // Remove company
  const removeCompany = () => {
    dispatch(deleteCompany(companyId));
    setEditCompanyModalOpen(false);
    clearCompany();
  };

  // Product actions
  const [product, setProduct] = useState({
    name: "",
    code: "",
    redirectUrl: "https://www.cozify.fi",
  });

  const clearProduct = () => {
    setProduct({
      name: "",
      code: "",
      redirectUrl: "https://www.cozify.fi",
    });
  };

  // Add product
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const addProduct = () => {
    dispatch(createProduct({ companyId, product }));
    setAddProductModalOpen(false);
    clearProduct();
  };

  // Edit product
  const [editProductModalOpen, setEditProductModalOpen] = useState(false);
  const editProduct = () => {
    dispatch(updateProduct({ companyId, product }));
    setEditProductModalOpen(false);
    clearProduct();
  };

  // Remove product
  const [removeProductDialogOpen, setRemoveProductDialogOpen] = useState(false);
  const removeProduct = () => {
    setRemoveProductDialogOpen(false);
    dispatch(deleteProduct({ companyId, product }));
    clearProduct();
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(5);

  const indexOfLastDevice = currentPage * productsPerPage;
  const indexOfFirstDevice = indexOfLastDevice - productsPerPage;
  const pageProducts = products.slice(indexOfFirstDevice, indexOfLastDevice);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const baseUrl =
    process.env.REACT_APP_BASE_URL === "https://portal2.dev.cozify.fi"
      ? "https://qr.dev.cozify.io"
      : "https://qr.cozify.fi";

  return (
    <Stack spacing={2} sx={{ my: 4 }}>
      <Stack
        spacing={1}
        component="header"
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "left", sm: "center" }}
      >
        <Typography variant="h5" component="h5">
          {name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => setAddProductModalOpen(true)}
            startIcon={<AddBoxIcon />}
          >
            Add product
          </Button>
          <Button
            onClick={() => {
              setEditCompanyModalOpen(true);
            }}
            startIcon={<EditIcon />}
          >
            Edit company
          </Button>
        </Box>
      </Stack>
      {products.length ? (
        <TableContainer component={Paper} variant="outlined" elevation={0}>
          <MUITable sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Code</TableCell>
                <TableCell align="right">Product pdf</TableCell>
                <TableCell align="right">QR codes</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageProducts.map((product) => (
                <TableRow
                  key={product.code}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {product.name}
                  </TableCell>
                  <TableCell align="right">{product.code}</TableCell>
                  <TableCell align="right">
                    <Link
                      href={product.redirectUrl}
                      target="_blank"
                      rel="noopener"
                    >
                      Link
                    </Link>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Link
                      href={`${baseUrl}/print/0/c/${companyId}/${product.code}?styleid=rgb`}
                      target="_blank"
                      rel="noopener"
                    >
                      RGB
                    </Link>

                    <Link
                      href={`${baseUrl}/print/0/c/${companyId}/${product.code}?styleid=rgb_negative`}
                      target="_blank"
                      rel="noopener"
                    >
                      RGB NEG
                    </Link>

                    <Link
                      href={`${baseUrl}/print/0/c/${companyId}/${product.code}?styleid=cmyk`}
                      target="_blank"
                      rel="noopener"
                    >
                      CMYK
                    </Link>

                    <Link
                      href={`${baseUrl}/print/0/c/${companyId}/${product.code}?styleid=cmyk_negative`}
                      target="_blank"
                      rel="noopener"
                    >
                      CMYK NEG
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        setProduct(product);
                        setEditProductModalOpen(true);
                      }}
                      aria-label="Edit product"
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        setProduct(product);
                        setRemoveProductDialogOpen(true);
                      }}
                      aria-label="Delete product"
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MUITable>
          <Pagination
            totalElements={products.length}
            paginate={paginate}
            elementsPerPage={productsPerPage}
          />
        </TableContainer>
      ) : (
        <p>0 products</p>
      )}
      <Modal
        open={editCompanyModalOpen}
        onClose={() => {
          setEditCompanyModalOpen(false);
          clearCompany();
        }}
        label="edit-company-modal"
      >
        <Typography variant="h5" component="h5">
          Edit company
        </Typography>
        <TextField label="Company id" value={companyId} disabled />
        <TextField
          label="Company name"
          value={company.name}
          onChange={(e) => setCompany({ ...company, name: e.target.value })}
        />
        <TextField
          label="Redirect url"
          value={company.redirectUrl}
          onChange={(e) =>
            setCompany({ ...company, redirectUrl: e.target.value })
          }
        />
        <Stack direction="row" spacing={2} alignSelf="flex-end">
          <Button
            disabled={products.length}
            onClick={removeCompany}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
          <Button onClick={editCompany} variant="contained">
            Save
          </Button>
        </Stack>
      </Modal>
      <Modal
        open={addProductModalOpen}
        onClose={() => {
          setAddProductModalOpen(false);
          clearProduct();
        }}
        label="add-product-modal"
      >
        <Typography variant="h5" component="h5">
          Add new product
        </Typography>
        <TextField label="Company" value={name} disabled />
        <TextField
          label="Product name"
          value={product.name}
          onChange={(e) => setProduct({ ...product, name: e.target.value })}
        />
        <TextField
          label="Product code"
          value={product.code}
          onChange={(e) => setProduct({ ...product, code: e.target.value })}
        />
        <TextField
          label="Redirect url"
          value={product.redirectUrl}
          onChange={(e) =>
            setProduct({ ...product, redirectUrl: e.target.value })
          }
        />
        <Stack direction="row" spacing={2} alignSelf="flex-end">
          <Button
            onClick={() => {
              setAddProductModalOpen(false);
              clearProduct();
            }}
          >
            Cancel
          </Button>
          <Button onClick={addProduct} variant="contained">
            Save
          </Button>
        </Stack>
      </Modal>
      <Modal
        open={editProductModalOpen}
        onClose={() => {
          setEditProductModalOpen(false);
          clearProduct();
        }}
        label="edit-product-modal"
      >
        <Typography variant="h5" component="h5">
          Edit product
        </Typography>
        <TextField label="Company" value={name} disabled />
        <TextField
          label="Product name"
          value={product.name}
          onChange={(e) => setProduct({ ...product, name: e.target.value })}
        />
        <TextField label="Product code" value={product.code} disabled />
        <TextField
          label="Redirect url"
          value={product.redirectUrl}
          onChange={(e) =>
            setProduct({ ...product, redirectUrl: e.target.value })
          }
        />
        <Stack direction="row" spacing={2} alignSelf="flex-end">
          <Button
            onClick={() => {
              setEditProductModalOpen(false);
              clearProduct();
            }}
          >
            Cancel
          </Button>
          <Button onClick={editProduct} variant="contained">
            Save
          </Button>
        </Stack>
      </Modal>
      <AlertDialog
        open={removeProductDialogOpen}
        title={`Are you sure you want to remove ${product.name}?`}
        onClose={() => setRemoveProductDialogOpen(false)}
        continueText="Remove product"
        onContinue={removeProduct}
      />
    </Stack>
  );
};

export default QrTable;
