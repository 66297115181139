import { chunk, extend, fromPairs, last } from "lodash-es";

export function entitiesByType(entities) {
  const entitiesByType = {};
  entities.forEach((entity) => {
    if (entitiesByType.hasOwnProperty(entity.type)) {
      entitiesByType[entity.type].push(entity);
    } else {
      entitiesByType[entity.type] = [entity];
    }
  });
  return entitiesByType;
}

export const entityReferenceFromPath = (path, entity) => {
  const re = new RegExp(`^.*/${entity}/[^/]+`);
  const regexMatch = path.match(re);

  if (!regexMatch) {
    return null;
  }

  return regexMatch[0];
};

// console.log(entitiesByType(entities));

export const entityTypeFromPath = (path) => {
  const re = /\/([^/]+)\/[^/]+$/;

  const match = path.match(re);

  if (!match) {
    return null;
  }

  return match[1];
};

export const parseItemPath = (item) => {
  const pathArr = item.path.split("/");

  pathArr.shift();
  const path = chunk(pathArr, 2);
  return fromPairs(path);
};
