import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectSiteById } from "../store/sites/selectors";
import { getUsers } from "../store/sites/reducer";
import SiteUsersTable from "./SiteUsersTable";
import Spinner from "./Spinner";

const SiteUsers = ({ id }) => {
  const dispatch = useDispatch();
  const site = useSelector((state) => selectSiteById(state, id));
  const { users, attributes, roleDefinitions, roleAssignments } = site;
  const { displayName } = attributes;

  const handleClick = (id) => {
    if (!users) {
      dispatch(getUsers(id));
    }
  };

  return (
    <Accordion sx={{ my: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => handleClick(id)}
      >
        <Typography component="h5" variant="h5">
          {displayName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {users ? (
          <SiteUsersTable
            users={users}
            roleAssignments={roleAssignments}
            roleDefinitions={roleDefinitions}
          />
        ) : (
          <Spinner />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SiteUsers;
