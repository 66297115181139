export const getSites = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/providers/cozify.directory/sites?api-version=1.2`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const { data } = await response.json();

  let sites = [];

  for (const el of data) {
    try {
      const roleDefinitions = await getRoleDefinitions(el.id, token);
      const roleAssignments = await getRoleAssignments(el.id, token);

      const site = {
        ...el,
        roleDefinitions: roleDefinitions,
        roleAssignments: roleAssignments,
      };

      sites.push(site);
    } catch (error) {}
  }
  return sites;
};

export const getRoleDefinitions = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/site/${id}/providers/cozify.authorization/roledefinitions`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  const { roleDefinitions } = await response.json();
  return roleDefinitions;
};

export const getRoleAssignments = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/site/${id}/providers/cozify.authorization/roleassignments`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  const { roleAssignments } = await response.json();
  return roleAssignments;
};

export const postSite = async (site, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/providers/cozify.directory/sites?api-version=1.2`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(site),
    }
  );

  const data = await response.json();
  return data;
};

export const deleteSite = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/providers/cozify.directory/sites/${id}?api-version=1.2`,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  return { id };
};

export const getEntities = async (id, token) => {
  const response = await fetch(
    `https://one.dev.cozify.fi/providers/cozify.directory/sites/${id}/{contacts/+/contact,building}/+{,/stair/+}{,/floor/+}{,/area/+}{,/user/+}{,/device/+}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  const { data } = await response.json();

  return { id, data };
};

export const postEntity = async (id, token, path, entity) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/providers/cozify.directory/sites/${id}${
      path ? path : ""
    }?api-version=1.2`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(entity),
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  const data = await response.json();
  return { id, data };
};

export const updateEntity = async (id, token, path, entity) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/providers/cozify.directory/sites/${id}${
      path ? path : ""
    }?api-version=1.2`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(entity),
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  const data = await response.json();
  return { id, path, data };
};

export const deleteEntity = async (id, resourcePath, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/providers/cozify.directory/sites/${id}${resourcePath}?api-version=1.2`,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  return { id, resourcePath };
};

export const getAvgSiteConditions = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/directory/conditions/computed/site/${id}/areas`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();
  return { id, data };
};

export const getSiteConditions = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_URL}/directory/conditions/site/${id}/building/{,+/stair/}+/floor/+/area/+/conditions/current`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const { data } = await response.json();

  return { id, data };
};

const apiService = {
  getSites,
  getEntities,
  getAvgSiteConditions,
  getSiteConditions,
  postSite,
  deleteSite,
  postEntity,
  updateEntity,
  deleteEntity,
};

export default apiService;
