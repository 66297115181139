import { useSelector } from "react-redux";
import { Box, Typography, Stack } from "@mui/material";
import { selectSiteConsumptionDetails } from "../store/site/selectors";

const SiteConsumptionDetails = () => {
  const {
    electricityTransmissionCents,
    electricityTaxCents,
    coldWaterEurosPerM3,
    hotWaterEurosPerM3,
  } = useSelector(selectSiteConsumptionDetails);

  return (
    <Box>
      <Typography variant="h6" component="h6">
        Consumption details
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }}>
        <Box sx={{ minWidth: 300, my: 1 }}>
          <Typography variant="overline">Electricity prices</Typography>
          <Typography variant="p" component="p">
            Electricity transmission: {electricityTransmissionCents} c/kWh
          </Typography>
          <Typography variant="p" component="p">
            Electricity tax: {electricityTaxCents} c/kWh
          </Typography>
        </Box>
        <Box sx={{ minWidth: 300, my: 1 }}>
          <Typography variant="overline">Water prices</Typography>
          <Typography variant="p" component="p">
            Cold water: {coldWaterEurosPerM3} €/m3
          </Typography>
          <Typography variant="p" component="p">
            Hot water: {hotWaterEurosPerM3} €/m3
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default SiteConsumptionDetails;
