import { createContext, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Keycloak from "keycloak-js";
import { setUserToken } from "../store/user/userSlice";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();

  function logout() {
    return keycloak.logout();
  }

  console.log(process.env.REACT_APP_BASE_URL);

  const loadConfig = useCallback(() => {
    // const keycloak =
    //   process.env.REACT_APP_BASE_URL === "https://portal.dev.cozify.fi"
    //     ? Keycloak("/keycloak-dev.json")
    //     : Keycloak("/keycloak-prd.json");

    const keycloak = Keycloak("/keycloak.json");

    keycloak
      .init({
        onLoad: "login-required",
        pkceMethod: "S256",
      })
      .then((authenticated) => {
        setIsAuthenticated(authenticated);
        setKeycloak(keycloak);
        setToken(keycloak.idToken);
        dispatch(setUserToken(keycloak.idToken));
      });
  }, [dispatch]);

  const value = { token, isAuthenticated, logout };

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
