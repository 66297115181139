import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = { companies: [], loading: false, error: false };

export const getCompanies = createAsyncThunk(
  "qr/getCompanies",
  async function (_, { rejectWithValue, getState }) {
    try {
      let companies = [];
      const userToken = getState().user.token;

      const res = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc?api-version=1.2`,
        {
          headers: { Authorization: "Bearer " + userToken },
        }
      );

      // if (!res.ok) {
      //   throw new Error(res.status);
      // }

      const { data } = await res.json();

      for (const company of data) {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc/companies/${company.companyId}/products?api-version=1.2`,

            {
              headers: { Authorization: "Bearer " + userToken },
            }
          );

          // if (!res.ok) {
          //   throw new Error(res.status);
          // }

          const { data } = await res.json();
          companies.push({ ...company, products: data });
        } catch (error) {
          console.log(error);
        }
      }
      return companies;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createCompany = createAsyncThunk(
  "qr/createCompany",
  async function (company, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const response = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc/companies?api-version=1.2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(company),
        }
      );
      return { ...company, products: [] };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "qr/updateCompany",
  async function ({ companyId, company }, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const response = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc/companies/${companyId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(company),
        }
      );
      const data = await response.json();
      return { companyId, data };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "qr/deleteCompany",
  async function (companyId, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const response = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc/companies/${companyId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      // console.log(response);
      return { companyId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createProduct = createAsyncThunk(
  "qr/createProduct",
  async function ({ companyId, product }, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const response = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc/companies/${companyId}/products?api-version=1.2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(product),
        }
      );
      return { companyId, product };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "qr/updateProduct",
  async function ({ companyId, product }, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const { code } = product;
      const response = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc/companies/${companyId}/products/${code}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(product),
        }
      );
      const data = await response.json();
      return { companyId, code, data };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "qr/deleteProduct",
  async function ({ companyId, product }, { rejectWithValue, getState }) {
    try {
      const token = getState().user.token;
      const { code } = product;
      const response = await fetch(
        `${process.env.REACT_APP_ONE_URL}/providers/cozify.barcodes/cbc/companies/${companyId}/products/${code}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      // console.log(response);
      return { companyId, code };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const qrSlice = createSlice({
  name: "qr",
  initialState,
  reducers: {},
  extraReducers: {
    [getCompanies.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getCompanies.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.companies = action.payload;
    },
    [getCompanies.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    [createCompany.pending]: (state) => {},
    [createCompany.fulfilled]: (state, action) => {
      state.companies.push(action.payload);
    },
    [createCompany.rejected]: (state, action) => {},
    [updateCompany.pending]: (state) => {},
    [updateCompany.fulfilled]: (state, action) => {
      state.companies = state.companies.map((c) =>
        c.companyId === action.payload.companyId
          ? {
              ...c,
              ...action.payload.data,
            }
          : c
      );
    },
    [updateCompany.rejected]: (state, action) => {},
    [deleteCompany.pending]: (state) => {},
    [deleteCompany.fulfilled]: (state, action) => {
      state.companies = state.companies.filter(
        (c) => c.companyId !== action.payload.companyId
      );
    },
    [deleteCompany.rejected]: (state, action) => {},
    [createProduct.pending]: (state) => {},
    [createProduct.fulfilled]: (state, action) => {
      const company = state.companies.find(
        (c) => c.companyId === action.payload.companyId
      );
      const index = state.companies.indexOf(company);
      state.companies[index].products.push(action.payload.product);
    },
    [createProduct.rejected]: (state, action) => {},
    [updateProduct.pending]: (state) => {},
    [updateProduct.fulfilled]: (state, action) => {
      const company = state.companies.find(
        (c) => c.companyId === action.payload.companyId
      );
      const index = state.companies.indexOf(company);

      state.companies[index].products = state.companies[index].products.map(
        (product) =>
          product.code === action.payload.code ? action.payload.data : product
      );
    },
    [updateProduct.rejected]: (state, action) => {},
    [deleteProduct.pending]: (state) => {},
    [deleteProduct.fulfilled]: (state, action) => {
      const company = state.companies.find(
        (c) => c.companyId === action.payload.companyId
      );
      const index = state.companies.indexOf(company);
      state.companies[index].products = state.companies[index].products.filter(
        (product) => product.code !== action.payload.code
      );
    },
    [deleteProduct.rejected]: (state, action) => {},
  },
});

export default qrSlice.reducer;
