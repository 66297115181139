import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { token } = useAuth();

  if (!token) {
    return <Navigate to="/redirect" state={{ from: location }} replace />;
    // Storing original location in Router state doesn't work,
    // because authentication takes user away from app
  }
  return children;
};

export default RequireAuth;
