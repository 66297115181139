import { Container as MUIContainer } from "@mui/material";

export default function AppContainer({ children }) {
  return (
    <MUIContainer
      component="main"
      maxWidth="xl"
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
        py: 2,
      }}
    >
      {children}
    </MUIContainer>
  );
}
