import { Stack, Pagination as MUIPagination } from "@mui/material";

const Pagination = ({
  totalElements = 100,
  elementsPerPage = 10,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalElements / elementsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Stack>
      <MUIPagination
        count={pageNumbers.length}
        onChange={(e) => paginate(e.currentTarget.textContent)}
        shape="rounded"
        color="primary"
        sx={{ my: 2, mx: "auto" }}
        hidePrevButton
        hideNextButton
      />
    </Stack>
  );
};

export default Pagination;
