import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Spinner } from "../../components";

const Redirect = () => {
  const { isAuthenticated } = useAuth();
  // const location = useLocation();
  // const fromPage = location.state?.from?.pathname;
  // console.log("frompage", fromPage); // undefined

  if (!isAuthenticated) {
    return <Spinner />;
  }

  return <Navigate to="/" />;
};

export default Redirect;
