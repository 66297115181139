import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Stack } from "@mui/material";
import { AreaHeader, Tabs } from "../../components";
import { selectAreaName } from "../../store/area/selectors";

const tabs = ["tab1", "devices", "tab3"];

const Area = () => {
  const name = useSelector(selectAreaName);

  return (
    <Fragment>
      <AreaHeader>
        <Typography variant="h5" component="h5">
          {name}
        </Typography>
      </AreaHeader>
      <Tabs tabs={tabs} />
      <Stack
        sx={{
          overflow: "scroll",
          flex: 1,
        }}
      >
        <Outlet />
      </Stack>
    </Fragment>
  );
};

export default Area;
