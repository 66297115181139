import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";

const SiteUsersTable = ({ users }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell align="right">Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row" variant="head">
                {user.firstName}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.lastName}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.username}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.roles.map((role) => (
                  <Chip key={role.name} label={role.name} />
                ))}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                {user.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SiteUsersTable;
