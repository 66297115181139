import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { selectSites } from "../store/sites/selectors";
import { selectSiteId } from "../store/site/selectors";
import { setSiteId } from "../store/site/reducer";

const SiteSelect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sites = useSelector(selectSites);
  const siteId = useSelector(selectSiteId);
  const [currentSite, setCurrentSite] = useState(siteId);

  const handleSiteChange = (e) => {
    const id = e.target.value;
    setCurrentSite(id);
    dispatch(setSiteId(id));
    navigate(`/sites/${id}/overview`, { replace: true });
  };

  return (
    <FormControl variant="outlined" sx={{ maxWidth: 280, my: 4 }}>
      <InputLabel>Select site</InputLabel>
      <Select
        label="Select site"
        value={currentSite || ""}
        onChange={handleSiteChange}
        defaultChecked={currentSite}
      >
        {sites.map((option) => {
          return (
            <MenuItem value={option.id} key={option.id}>
              {option.attributes.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SiteSelect;
