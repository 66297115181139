import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
};

export const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setSiteId(state, action) {
      state.id = action.payload;
    },
  },
});

export const { setSiteId } = siteSlice.actions;

export default siteSlice.reducer;
