import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import RequireAuth from "./hoc/RequireAuth";
import { AppLayout } from "./components";
import {
  Home,
  Redirect,
  Sites,
  Site,
  SiteOverview,
  SiteAreas,
  SiteAdmin,
  Area,
  AreaOverview,
  AreaDevices,
  AreaAdmin,
  Users,
  QrCodes,
  Account,
} from "./screens";


function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="redirect" element={<Redirect />} />
          <Route
            index
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="sites"
            element={
              <RequireAuth>
                <Sites />
              </RequireAuth>
            }
          />
          <Route
            path="sites/:siteId"
            element={
              <RequireAuth>
                <Site />
              </RequireAuth>
            }
          >
            <Route
              path="overview"
              element={
                <RequireAuth>
                  <SiteOverview />
                </RequireAuth>
              }
            />
            <Route
              path="areas"
              element={
                <RequireAuth>
                  <SiteAreas />
                </RequireAuth>
              }
            >
              <Route path=":id" element={<Area />}>
                <Route path="tab1" element={<AreaOverview />} />
                <Route path="devices" element={<AreaDevices />} />
                <Route path="tab3" element={<AreaAdmin />} />
              </Route>
            </Route>
            <Route
              path="admin"
              element={
                <RequireAuth>
                  <SiteAdmin />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="users"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path="qr"
            element={
              <RequireAuth>
                <QrCodes />
              </RequireAuth>
            }
          />
          <Route
            path="account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
