import { useLocation, Link } from "react-router-dom";
import { Tabs as MUITabs, Tab } from "@mui/material";

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const match = pathname.includes(pattern);

    if (match) {
      return pattern;
    }
  }

  return null;
}

const Tabs = ({ tabs }) => {
  const routeMatch = useRouteMatch(tabs);
  const currentTab = routeMatch || false;

  return (
    <MUITabs
      value={currentTab}
      sx={{
        my: 2,
      }}
    >
      {tabs.map((tab) => (
        <Tab key={tab} label={tab} value={tab} component={Link} to={tab} />
      ))}
    </MUITabs>
  );
};

export default Tabs;
