import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar as MUIAppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Tooltip,
  MenuItem,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuth } from "../hooks/useAuth";

const pages = ["sites", "users", "qr"];

const AppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <MUIAppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ alignItems: "stretch" }}>
          <IconButton
            size="large"
            component={Link}
            to="/"
            color="inherit"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <PowerSettingsNewIcon fontSize="large" />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              onClick={(e) => setAnchorElNav(e.currentTarget)}
              color="inherit"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    setAnchorElNav(null);
                    navigate(page);
                  }}
                >
                  <Typography textTransform="capitalize">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                md: "none",
                alignItems: "center",
              },
            }}
          >
            <PowerSettingsNewIcon fontSize="large" />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                component={Link}
                to={page}
                onClick={() => setAnchorElNav(null)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  borderRadius: 0,
                  px: 4,
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Cozify account">
              <IconButton onClick={(e) => setAnchorElUser(e.currentTarget)}>
                <AccountCircleIcon sx={{ color: "white" }} fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              <MenuItem onClick={() => setAnchorElUser(null)}>
                <Typography
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  Manage account
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorElUser(null);
                  logout();
                }}
              >
                <Typography>Log out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </MUIAppBar>
  );
};
export default AppBar;
