import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { selectSiteAddressDetails } from "../store/site/selectors";

const SiteAddressDetails = () => {
  const address = useSelector(selectSiteAddressDetails);

  return (
    <Box>
      <Typography variant="h6" component="h6">
        Address
      </Typography>
      <Typography variant="p" component="p" sx={{ my: 1 }}>
        {address}
      </Typography>
    </Box>
  );
};

export default SiteAddressDetails;
