import { Fragment } from "react";
import { AreaWidgets } from "../../components";

const AreaOverview = () => {
  return (
    <Fragment>
      <AreaWidgets />
    </Fragment>
  );
};

export default AreaOverview;
