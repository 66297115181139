import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import Widget from "./Widget";
import {
  selectAreaDevices,
  selectAreaConditions,
  selectAreaResidents,
} from "../store/area/selectors";

const AreaWidgets = () => {
  const { temperature, humidity } = useSelector(selectAreaConditions);
  const devices = useSelector(selectAreaDevices);
  const residents = useSelector(selectAreaResidents);

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
      {temperature && (
        <Widget value={`${temperature}°C`} name="Temperature (avg.)" />
      )}
      {humidity && <Widget value={`${humidity}%`} name="Humidity (avg.)" />}
      {devices.length > 0 && <Widget value={devices.length} name="Devices" />}
      {residents && <Widget value={residents} name="Residents" />}
    </Stack>
  );
};

export default AreaWidgets;
