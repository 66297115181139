import { createSlice } from "@reduxjs/toolkit";

const initialState = { path: null };

export const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    setAreaPath(state, action) {
      state.path = action.payload;
    },
  },
});

export const { setAreaPath } = areaSlice.actions;

export default areaSlice.reducer;
