import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import Widget from "./Widget";
import {
  selectSiteAreas,
  selectSiteDevices,
  selectAvgSiteConditions,
} from "../store/site/selectors";

const SiteWidgets = () => {
  const areas = useSelector(selectSiteAreas);
  const devices = useSelector(selectSiteDevices);

  const { avgTemperature, avgHumidity } = useSelector(selectAvgSiteConditions);

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
      {areas && <Widget value={areas.length} name="Areas" />}
      {devices && <Widget value={devices.length} name="Devices" />}

      {avgTemperature && (
        <Widget value={`${avgTemperature}°C`} name="Temperature" />
      )}
      {avgHumidity && <Widget value={`${avgHumidity}%`} name="Humidity" />}
    </Stack>
  );
};

export default SiteWidgets;
