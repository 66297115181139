import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { selectSite } from "../store/site/selectors";
import Node from "./SiteTreeNode";

const SiteTree = () => {
  const site = useSelector(selectSite);
  const { entities = [] } = site;

  const level1 = entities.filter((el) => el.depth === 2);
  const level2 = entities.filter((el) => el.depth === 4);
  const level3 = entities.filter((el) => el.depth === 6);
  const level4 = entities.filter((el) => el.depth === 8);
  const level5 = entities.filter((el) => el.depth === 10);

  return (
    <Box sx={{ my: 2 }}>
      <Node node={site}>
        {level1 &&
          level1
            // .sort((a, b) => a.path.localeCompare(b.path))
            .map((level1el) => {
              return (
                <Node node={level1el} key={level1el.path}>
                  {level2 &&
                    level2
                      // .sort((a, b) => a.path.localeCompare(b.path))
                      .filter((level2el) =>
                        level2el.path.includes(level1el.path)
                      )
                      .map((level2el) => {
                        return (
                          <Node node={level2el} key={level2el.path}>
                            {level3 &&
                              level3
                                // .sort((a, b) => a.path.localeCompare(b.path))
                                .filter((level3el) =>
                                  level3el.path.includes(level2el.path)
                                )
                                .map((level3el) => {
                                  return (
                                    <Node node={level3el} key={level3el.path}>
                                      {level4 &&
                                        level4
                                          // .sort((a, b) =>
                                          //   a.path.localeCompare(b.path)
                                          // )
                                          .filter((level4el) =>
                                            level4el.path.includes(
                                              level4el.path
                                            )
                                          )
                                          .map((level4el) => {
                                            return (
                                              <Node
                                                node={level4el}
                                                key={level4el.path}
                                              >
                                                {level5 &&
                                                  level5
                                                    // .sort((a, b) =>
                                                    //   a.path.localeCompare(b.path)
                                                    // )
                                                    .filter((level5el) =>
                                                      level5el.path.includes(
                                                        level4el.path
                                                      )
                                                    )
                                                    .map((level5el) => {
                                                      return (
                                                        <Node
                                                          node={level5el}
                                                          key={level5el.path}
                                                        ></Node>
                                                      );
                                                    })}
                                              </Node>
                                            );
                                          })}
                                    </Node>
                                  );
                                })}
                          </Node>
                        );
                      })}
                </Node>
              );
            })}
      </Node>
    </Box>
  );
};

export default SiteTree;
