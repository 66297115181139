import { Box } from "@mui/material";
import { SiteWidgets, SiteTree } from "../../components";

const SiteOverview = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <SiteWidgets />
      <SiteTree />
    </Box>
  );
};

export default SiteOverview;
