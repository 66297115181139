import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// https://mui.com/components/css-baseline/
import { Stack } from "@mui/material";

const theme = createTheme({
  // Overwrite default theme object here
  // https://mui.com/customization/default-theme/
  // palette: {
  //   primary: {
  //     main: "#1b1b1b",
  //   },
  // },

  typography: {
    button: {
      textTransform: "initial",
    },
  },
});

export default function App({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack
        sx={{
          height: "100vh",
          background: "#f8f8f8",
        }}
      >
        {children}
      </Stack>
    </ThemeProvider>
  );
}
